/* styles.css */

header {
    background: transparent;
    color: white;
    text-align: center;
    position: absolute;
    z-index: 1;
    width: 100%;
}

footer {
    background-image: url('../images/footer-bg.webp');
    color: white;
    padding: 3em 0;
    text-align: center;
    width: 100%;
    bottom: 0;
    background-size: cover;
}
/* header style */
.navbar-brand {
    text-align: left;
}
.navbar-nav li{
    text-transform: uppercase;
    font-size: 1.2em;
}
.navbar-nav li.active a{
    color: #690807;
    font-weight: 500;
}
/*footer css*/
.footerLeftContent{
    text-align: left;
}
.footerLeftContent p{
    margin-bottom: .5rem;
    color: #baa674;
}
.footerLeftContent h2{
    color: #baa674;
}
.footerLeftContent p a{
    color: #baa674;
    text-decoration: none;
}
footer .hr {
    border-top: 2px solid #baa674;
    margin: 15px 0;
}
.footerSocial li a{
    color: #baa674;
}
.footerSocial li a svg{
    font-size: 2em;
}

/* first section css */
.homeFirst{
    min-height: 100vh;
}
.homeFirst img{
    min-height: 100vh;
    object-fit: cover;
    object-position: center;
}

/* second section css */
.homeSecond{
    min-height: 100vh;
    background-image: url('../images/banner-second.webp');
    background-size: cover;
    background-position: center;
}

/* third section css */
.homeThird{
    min-height: 100vh;
    background-image: url('../images/third-banner.webp');
    background-size: cover;
    background-position: center;
}

/* fourth section css */
.homeFourth{
    min-height: 100vh;
    background-image: url('../images/section-four.webp');
    background-size: cover;
    background-position: bottom;
}

/* fifth section css */
.homeFifth{
    min-height: 100vh;
    background-image: url('../images/bottle-bg.webp');
    background-size: cover;
    background-position: bottom;
}